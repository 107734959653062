"use strict";

import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";

import {AuthService} from "../_services/auth.service";

@Injectable()
class AdminGuard implements CanActivate {

  constructor(private auth: AuthService, private router: Router) {}

  canActivate() {
    if(this.auth.isLoggedIn()){
      return true;
    } else{
      this.router.navigate(["login"]);
      return false;
    }
  }
}

export {AdminGuard};
