import {Component, OnInit} from "@angular/core";

@Component({
  moduleId: module.id,
  templateUrl: 'gebrauchtwagen.component.html',
  styleUrls: ['./gebrauchtwagen.component.scss']
})

export class GebrauchtwagenComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}
