"use strict";

declare const google: any;

import {MapsAPILoader} from "@agm/core";
import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';

interface ILocationService {
}

@Injectable()
class LocationService implements ILocationService {

    private geocoder;
    private autocompleteService;

    private currentLocationResults: [google.maps.places.PlaceResult];
    private currentClientLocation: google.maps.places.PlaceResult;
    private currentClientPosition: Position;

    private clientLocation: ReplaySubject<google.maps.places.PlaceResult>;
    private clientPosition: ReplaySubject<Position>;

    constructor(private mapsAPILoader: MapsAPILoader) {
        this.clientLocation = new ReplaySubject();
        this.clientPosition = new ReplaySubject();
        this.mapsAPILoader.load().then(() => {
            this.geocoder = new google.maps.Geocoder();
            this.autocompleteService = new google.maps.places.AutocompleteService();
            this.clientLocation.subscribe(location => this.currentClientLocation = location);
            this.clientPosition.subscribe(position => this.currentClientPosition = position);

            this.loadClientLocation();
        });
    }

    private loadClientLocation() {
        this.loadClientPosition().subscribe((position) => {
            this.geocodeLatLng(position.coords.latitude, position.coords.longitude, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    this.currentLocationResults = results;
                    if (results[0] != null) {
                        //closest approximate returned by google for page display
                        let approx_location = results.find(r => r.geometry.location_type == "APPROXIMATE");
                        this.setClientLocation(approx_location ? approx_location : results[0]);
                    }
                }
            });
        });
        return this.getClientLocation();
    }

    private loadClientPosition() {
        navigator.geolocation.getCurrentPosition((position) => {
            this.setClientPosition(position);
        });
        return this.getClientPosition();
    }

    public getClientPosition() {
        return this.clientPosition.asObservable();
    }

    public setClientPosition(position) {
        this.clientPosition.next(position);
    }

    public getClientLocation() {
        return this.clientLocation.asObservable();
    }

    public setClientLocation(location) {
        this.clientLocation.next(location);
    }

    public getClientLatLon() {
        return this.currentClientLocation ? [this.currentClientLocation.geometry.location.lng() ,this.currentClientLocation.geometry.location.lat()] : [];
    }

    public getClientAddress() {
        return this.currentClientLocation ? this.currentClientLocation.formatted_address : "";
    }

    public geocodeLatLng(lat: number, lng: number, success) {
        let latlng = new google.maps.LatLng(lat, lng);
        let request = { location: latlng };
        this.geocoder.geocode(request, success);
    }

    public getPredictions(search, success) {
        this.autocompleteService.getQueryPredictions({ input: search }, success);
    }

    public getPlaceDetails(id, success) {
        this.geocoder.geocode({placeId: id}, success);
    }

}

export {ILocationService, LocationService}
