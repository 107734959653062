"use strict";

import {Component, Input, OnInit} from '@angular/core';
import {UserPreferencesService} from "../_services/userPreferencesService.service";

@Component({
  moduleId: module.id,
  selector: 'website-footer',
  templateUrl: 'website-footer.component.html',
  styleUrls: ['./website-footer.component.scss']
})

export class WebsiteFooterComponent implements OnInit {

  @Input("showContact")
  public showContact = true;
  @Input("showPartners")
  public showPartners = true;

  constructor(private userPreferencesService: UserPreferencesService) {
  }

  ngOnInit() {
  }

  localeChanged(locale: any) {
    if(locale)
      this.userPreferencesService.setLocale(locale.shortCode);
  }
}
