import {Component, OnInit} from "@angular/core";

@Component({
    moduleId: module.id,
    templateUrl: 'impressum.component.html',
    styleUrls: ['./impressum.component.scss']
})

export class ImpressumComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}