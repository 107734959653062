import {Component, OnInit} from "@angular/core";

@Component({
    moduleId: module.id,
    templateUrl: 'datenschutz.component.html',
    styleUrls: ['./datenschutz.component.scss']
})

export class DatenschutzComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
