"use strict";

import {Component, OnInit} from "@angular/core";

import {AuthService} from "../../_services/auth.service";

@Component({
  moduleId: module.id,
  templateUrl: 'accounting.component.html',
  styleUrls: ['./accounting.component.scss']
})

export class AccountingComponent implements OnInit {

  public user;
  public project;
  public loadingProject = false;

  constructor(private auth: AuthService) {
  }

  ngOnInit() {
    /*this.authService.isLoggedIn()
      .subscribe(user => {
        this.user = user;
        if (this.user) {
          this.updateData();
        }
      });*/
  }

  updateData() {
    // TODO: get project data
  }

}
