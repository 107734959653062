"use strict";

import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {TruncateModule} from "ng2-truncate";
import {MomentModule} from 'angular2-moment';
import {TranslateModule} from '@ngx-translate/core';

import {BsDropdownModule, ButtonsModule, CarouselModule, TooltipModule, PaginationModule, TabsModule, ModalModule} from 'ngx-bootstrap';
import {AngularMultiSelectModule} from 'angular2-multiselect-dropdown';
import {Angulartics2Module} from 'angulartics2';
import {Angulartics2GoogleAnalytics} from 'angulartics2/ga'
import {AgmCoreModule} from "@agm/core";
import {NgPipesModule} from 'ng-pipes';
import {FacebookModule} from 'ngx-facebook';
import {RecaptchaModule} from "ng-recaptcha";
import {ScrollSpyModule} from 'ngx-scrollspy'
import {BsDatepickerModule} from 'ngx-bootstrap';
import {FileDropModule} from 'ngx-file-drop';

import {routing} from './website.routing';
import {WebsiteComponent} from './website.component';

import {AuthService} from './_services/auth.service';
import {CountryService} from "./_services/country.service";
import {UserPreferencesService} from "./_services/userPreferencesService.service";
import {ProjectService} from "./_services/project.service";
import {LocationService} from "./_services/location.service";

import {AuthGuard} from "./_guards/auth.guard";
import {AdminGuard} from "./_guards/admin.guard";
import {LoginGuard} from "./_guards/login.guard";

import {WebsiteHeaderComponent} from './website-header/website-header.component';
import {WebsiteFooterComponent} from './website-footer/website-footer.component';

import {HomeComponent} from './home/home.component';
import {LoginComponent} from "./login/login.component";
import {UeberUnsComponent} from "./ueber-uns/ueber-uns.component";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {ImpressumComponent} from "./impressum/impressum.component";
import {AccountingComponent} from "./dashboard/accounting/accounting.component";

import {LocaleSelectorComponent} from "./_components/locale-selector/locale-selector.component";
import {LocationInputComponent} from "./_components/location-input/location-input.component";
import {LeistungenComponent} from "./leistungen/leistungen.component";
import {AGBComponent} from "./agb/agb.component";
import {DatenschutzComponent} from "./datenschutz/datenschutz.component";
import {KontaktComponent} from "./kontakt/kontakt.component";
import {GebrauchtwagenComponent} from "./gebrauchtwagen/gebrauchtwagen.component";

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    routing,

    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    ButtonsModule.forRoot(),
    PaginationModule.forRoot(),
    TabsModule.forRoot(),
    CarouselModule.forRoot(),
    TooltipModule.forRoot(),
    FacebookModule.forRoot(),
    ScrollSpyModule.forRoot(),
    TruncateModule,
    AngularMultiSelectModule,
    MomentModule,
    RecaptchaModule.forRoot(),
    NgPipesModule,
    TranslateModule.forRoot(),
    FileDropModule,

    Angulartics2Module.forRoot([Angulartics2GoogleAnalytics]),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDin3s7JnIva_Ny_I7y3xLXAwAmmyZb3Tg",
      libraries: ["places"]
    }),

    BsDatepickerModule.forRoot()
  ],
  declarations: [
    WebsiteComponent,
    WebsiteHeaderComponent,
    WebsiteFooterComponent,
    HomeComponent,
    LoginComponent,
    ImpressumComponent,
    DashboardComponent,
    AccountingComponent,
    UeberUnsComponent,
    LeistungenComponent,
    DatenschutzComponent,
    AGBComponent,
    KontaktComponent,
    GebrauchtwagenComponent,

    LocaleSelectorComponent,
    LocationInputComponent
  ],
  providers: [
    AuthService,
    CountryService,
    UserPreferencesService,
    ProjectService,
    LocationService,
    AuthGuard,
    AdminGuard,
    LoginGuard,
    {
      provide: LOCALE_ID,
      useValue: navigator.language
    }
  ],
  bootstrap: [WebsiteComponent]
})
export class WebsiteModule {
}
