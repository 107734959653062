"use strict";

import {Component, OnInit} from "@angular/core";
import Swiper from "swiper";

@Component({
  moduleId: module.id,
  templateUrl: 'home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
    //this.getUserGeoLocation();
    let mySwiper = new Swiper('.swiper-container', {
      // Optional parameters
      direction: 'vertical',
      loop: true,

      // If we need pagination
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
    });
  }

  private getUserGeoLocation() {
    if ("geolocation" in navigator) {
      let options = {
        enableHighAccuracy: true,
        timeout: 60000,
        maximumAge: 0
      };

      navigator.geolocation.getCurrentPosition(position => {
        console.log(position);
      }, error => console.error(error), options);
    }
  }
}
