import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../_environments/environment';

import 'rxjs/Rx';

interface IProjectService {
  getProject(searchParams?: any);
}

@Injectable()
class ProjectService implements IProjectService {

  private apiUrl = environment.apiUrl + "/project";
  private httpOptions = {withCredentials: true};

  constructor(private httpClient: HttpClient) {
  }

  getProject(searchParams?: any) {
    let requestOptions = {withCredentials: true, params: null};
    if (searchParams) {
      requestOptions.params = new HttpParams();
      Object.keys(searchParams).forEach(key => {
        requestOptions.params.set(key, searchParams[key]);
      });
    }
    return this.httpClient.get(this.apiUrl, requestOptions).map((response: Response) => response.json());
  }

  createProject(project) {
    return this.httpClient.post(this.apiUrl, project, this.httpOptions).map((response: Response) => response.json());
  }
}

export {IProjectService, ProjectService}
