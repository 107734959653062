import {Component, OnInit} from "@angular/core";

@Component({
  moduleId: module.id,
  templateUrl: 'ueber-uns.component.html',
  styleUrls: ['./ueber-uns.component.scss']
})

export class UeberUnsComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}
