import {Routes, RouterModule} from '@angular/router';

import {HomeComponent} from './home/home.component';
import {LoginComponent} from "./login/login.component";
import {ImpressumComponent} from "./impressum/impressum.component";

import {AuthGuard} from "./_guards/auth.guard";
import {AdminGuard} from "./_guards/admin.guard";
import {LoginGuard} from "./_guards/login.guard";
import {UeberUnsComponent} from "./ueber-uns/ueber-uns.component";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {AccountingComponent} from "./dashboard/accounting/accounting.component";
import {LeistungenComponent} from "./leistungen/leistungen.component";
import {DatenschutzComponent} from "./datenschutz/datenschutz.component";
import {AGBComponent} from "./agb/agb.component";
import {KontaktComponent} from "./kontakt/kontakt.component";
import {GebrauchtwagenComponent} from "./gebrauchtwagen/gebrauchtwagen.component";

const appRoutes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'leistungen', component: LeistungenComponent},
  {path: 'gebrauchtwagen', component: GebrauchtwagenComponent},
  {path: 'ueber-uns', component: UeberUnsComponent},
  {path: 'kontakt', component: KontaktComponent},
  {path: 'login', component: LoginComponent, canActivate: [LoginGuard]},
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  {path: 'dashboard/accounting', component: AccountingComponent, canActivate: [AuthGuard]},
//    {path: 'admin', component: AdminComponent, canActivate: [AdminGuard]},
  {path: 'impressum', component: ImpressumComponent},
  {path: 'datenschutz', component: DatenschutzComponent},
  {path: 'agb', component: AGBComponent},
  {path: '**', redirectTo: ''}
];

const routing = RouterModule.forRoot(appRoutes);

export {routing};
