"use strict";

import {Component, OnInit} from "@angular/core";

@Component({
  moduleId: module.id,
  templateUrl: 'leistungen.component.html',
  styleUrls: ['./leistungen.component.scss']
})

export class LeistungenComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {

  }
}
