"use strict";

import {Deserializable} from "./deserializable.model";

export class User implements Deserializable {
  name: string;
  address: string;
  email: string;
  phone: string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
