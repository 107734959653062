"use strict";

import {Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';

import {Locales} from "../../_data/locales";
import {UserPreferencesService} from "../../_services/userPreferencesService.service";

@Component({
  selector: 'locale-selector',
  templateUrl: 'locale-selector.component.html',
  styleUrls: ['./locale-selector.component.scss']
})
export class LocaleSelectorComponent implements OnDestroy {
  @Input("locale")
  public selectedLocale;
  @Input("locales")
  public locales = [];
  @Input("showLabel")
  public showLabel = false;
  @Input("dropup")
  public dropup = false;
  @Input("showBorder")
  public showBorder = false;

  private localeSub: Subscription;
  private defaultLocale;

  @Output()
  onChange = new EventEmitter<any>();

  constructor(private userPreferencesService: UserPreferencesService, private translateService: TranslateService) {
    this.defaultLocale = "en";
    this.locales = Locales;
    this.localeSub = this.userPreferencesService.getLocale().subscribe(locale => { this.localeChanged(locale); });
  }

  selectLocale(locale) {
    this.selectedLocale = locale;
    this.onChange.emit(locale);
  }

  localeChanged(localeShort) {
    let locale = this.locales.find(l => l.shortCode == localeShort);
    if(!locale)
      this.locales.find(l => l.shortCode == this.defaultLocale);

    this.translateService.use(localeShort);
    this.selectedLocale = locale;
  }

  ngOnDestroy() {
    this.localeSub.unsubscribe();
  }
}
