import {Router} from "@angular/router";
import {Component, OnInit} from '@angular/core';
import {AuthService} from "../_services/auth.service";
import {UserPreferencesService} from "../_services/userPreferencesService.service";

@Component({
  moduleId: module.id,
  selector: 'website-header',
  templateUrl: 'website-header.component.html',
  styleUrls: ['./website-header.component.scss']
})

export class WebsiteHeaderComponent implements OnInit {

  public user;

  constructor(private router: Router, private auth: AuthService, private userPreferencesService: UserPreferencesService) {
  }

  ngOnInit(): void {
    this.auth.isLoggedIn()
      .subscribe(user => {
        this.user = user;
      });
  }

  logout() {
    this.auth.logout();
    /*.subscribe(response => window.location.href = "/", error => console.log(error));*/
  }

  localeChanged(locale: any) {
    this.userPreferencesService.setLocale(locale.shortCode);
  }
}
