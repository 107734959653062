import {Component, OnInit} from "@angular/core";

@Component({
    moduleId: module.id,
    templateUrl: 'agb.component.html',
    styleUrls: ['./agb.component.scss']
})

export class AGBComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
