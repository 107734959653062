"use strict";

import {BehaviorSubject, Observable} from "rxjs";
import {Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

interface IUserPreferencesService {
  getLocale();
  getLocale(locale: string);
}

@Injectable()
class UserPreferencesService implements IUserPreferencesService {

  private locale = new BehaviorSubject<string>(null);

  constructor(private translateService: TranslateService) {
    this.determineLocale();
  }

  getLocale(): Observable<string> {
    return this.locale.asObservable();
  }

  setLocale(locale: string) {
    this.translateService.use(locale);
    localStorage.setItem("language", locale);
    window.scrollTo(0, 0);
    this.locale.next(locale);
  }

  determineLocale() {
    let languageCode = localStorage.getItem("language") != null ? localStorage.getItem("language") : navigator.language;
    let language = languageCode.indexOf("-") != -1 ? languageCode.split("-")[0] : languageCode;
    let locale = language.indexOf("-") != -1 ? language.split("-")[0] : language;
    this.setLocale(locale);
  }

}

export {IUserPreferencesService, UserPreferencesService}
