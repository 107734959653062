"use strict";

import {of as observableOf} from 'rxjs';
import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";
import {AuthService} from "../_services/auth.service";

@Injectable()
class LoginGuard implements CanActivate {

  constructor(private auth: AuthService, private router: Router) {}

  canActivate() {
    return this.auth.isLoggedIn()
      .map(user => {
        this.router.navigate(['/dashboard']);
        return false;
      })
      .catch(error => {
        return observableOf(true);
      });
  }
}

export {LoginGuard};
