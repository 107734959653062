"use strict";

import 'rxjs/Rx';

import {Router} from "@angular/router";
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {environment} from '../_environments/environment';

interface IAuthService {
  isLoggedIn();
  register(user: any);
  login(email: string, password: string);
  getUserByConfirmationId(confirmationId: string);
  logout();
}

@Injectable()
class AuthService implements IAuthService {

  private apiUrl = environment.apiUrl + "/auth";
  private httpOptions = {withCredentials: true};

  constructor(private httpClient: HttpClient, private router: Router) {
  }

  sendToken(token: string) {
    localStorage.setItem("LoggedInUser", token);
  }
  getToken() {
    return localStorage.getItem("LoggedInUser");
  }
  isLoggedIn() {
    return this.httpClient.get(this.apiUrl, this.httpOptions);
    //return this.getToken() !== null;
  }
  logout() {
    return this.httpClient.delete(this.apiUrl, this.httpOptions);
    //localStorage.removeItem("LoggedInUser");
    /*this.router.navigate(["home"]);*/
  }

  /**************************/

  isLoggedInOld() {
    return this.httpClient.get(this.apiUrl, this.httpOptions).map((response: Response) => response.json());
  }

  register(user: any) {
    return this.httpClient.post(this.apiUrl + "/signup", user, this.httpOptions).map((response: Response) => response.json());
  }

  login(email: string, password: string) {
    return this.httpClient.post(this.apiUrl, { email, password }, this.httpOptions).map((response: Response) => response.json());
  }

  resetPassword(email: string, password?: string, confirmationId?: string) {
    return this.httpClient.put(this.apiUrl + "/resetpassword", { email, password, confirmationId }, this.httpOptions).map((response: Response) => response.json());
  }

  updateUser(user) {
    return this.httpClient.put(this.apiUrl + "/user/" + user._id, user, this.httpOptions).map((response: Response) => response.json());
  }

  createUser(user) {
    return this.httpClient.post(this.apiUrl + "/user", user, this.httpOptions).map((response: Response) => response.json());
  }

  confirmEmail(confirmationId) {
    return this.httpClient.put(this.apiUrl + "/confirm/" + confirmationId, {}, this.httpOptions).map((response: Response) => response.json());
  }

  logoutOld() {
    return this.httpClient.delete(this.apiUrl, this.httpOptions);
  }

  getUserByConfirmationId(confirmationId: string) {
    return this.httpClient.get(this.apiUrl + "/userConfirmation/" + confirmationId, this.httpOptions).map((response: Response) => response.json());
  }

  getUsers(searchParams?: any) {
    let requestOptions = { withCredentials: true, params: null };
    if (searchParams) {
      requestOptions.params = new HttpParams();
      Object.keys(searchParams).forEach(key => {
        requestOptions.params.set(key, searchParams[key]);
      });
    }
    return this.httpClient.get(this.apiUrl + "/user", requestOptions).map((response: Response) => response.json());
  }

  getUser(userId) {
    return this.httpClient.get(this.apiUrl + "/user/" + userId, this.httpOptions).map((response: Response) => response.json());
  }
}

export {IAuthService, AuthService}
