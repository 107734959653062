"use strict";

import * as moment from "moment";
import {Component, OnInit} from "@angular/core";
import {AuthService} from "../_services/auth.service";
import {environment} from "../_environments/environment";

@Component({
  moduleId: module.id,
  templateUrl: 'dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {

  public user;
  public projects;
  private cdnUrl;

  constructor(private authService: AuthService) {
    this.cdnUrl = environment.apiUrl ? environment.apiUrl : window.location.protocol + "//api." + window.location.hostname;
  }

  ngOnInit() {
    this.authService.isLoggedIn()
      .subscribe(user => {
        this.user = user;
        if (this.user) {
          this.updateData();
        }
      });
  }

  updateData() {
    /*this.projectService.getProjects()
      .subscribe(projects => {
        this.projects = projects;
      });*/
  }

  getOrganizationLogo(organization) {
    if(organization.logo) {
      if(organization.logo.path)
        return [this.cdnUrl, organization.logo.path].join("/");
      else
        return organization.logo;
    }
  }

  getProfileImage(user) {
    if (user.profileImage) {
      if (user.profileImage.path)
        return [this.cdnUrl, user.profileImage.path].join("/");
      else
        return user.profileImage;
    }
  }

  getFirstname(user) {
    return user.name.split(" ")[0];
  }
}
