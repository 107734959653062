"use strict";

let Locales = [{
  "shortCode": "de",
  "name": "German",
  "nativeName": "Deutsch",
  "flag": "https://restcountries.eu/data/deu.svg"
}, {
  "shortCode": "en",
  "name": "English",
  "nativeName": "English",
  "flag": "https://restcountries.eu/data/gbr.svg"
}];

export {Locales};
