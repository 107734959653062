"use strict";

import * as moment from "moment";
import {NavigationEnd, Router} from "@angular/router";
import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'website',
    templateUrl: './website.component.html',
    styleUrls: ['./website.component.scss']
})
export class WebsiteComponent implements OnInit {
    private previousRoute = [];

    constructor(private router: Router) {
      moment.locale('de');
    }

    ngOnInit() {
      this.initEventHandlers();
    }

    initEventHandlers() {
      this.router.events
        .filter(event => event instanceof NavigationEnd)
        .subscribe(e => {
          window.scrollTo(0, 0);
          this.previousRoute.push(e);
        });
    }

    getPreviousRoute() {
        return this.previousRoute.length>1 ? this.previousRoute[this.previousRoute.length-2] : null;
    }
}
