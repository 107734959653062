"use strict";

declare const google: any;

import {MapsAPILoader} from "@agm/core";
import {Component, ElementRef, OnInit, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';

import {LocationService} from "../../_services/location.service";

@Component({
    selector: 'location-input',
    templateUrl: 'location-input.component.html',
    styleUrls: ['./location-input.component.scss']
})
export class LocationInputComponent implements OnInit {

    @ViewChild("locationInput")
    public locationInputElementRef: ElementRef;
    public locationAutocomplete;
    @Input()
    public location: google.maps.places.PlaceResult;
    @Input()
    public locationText;
    @Input()
    public placeholder;
    @Output() 
    onChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(private mapsAPILoader: MapsAPILoader, private locationService: LocationService, private cdr: ChangeDetectorRef) {
        this.placeholder = "";
    }

    ngOnInit() {
        this.locationService.getClientLocation().subscribe(
            location => {
                this.location = location;
                this.updateLocationText();
            }
        );
        this.mapsAPILoader.load().then(() => {
            let autoCompleteConfig: google.maps.places.AutocompleteOptions = { types: ["(regions)"] };
            //autoCompleteConfig.componentRestrictions.country
            this.locationAutocomplete = new google.maps.places.Autocomplete(this.locationInputElementRef.nativeElement, {
                types: ["(regions)"]
            });
            this.locationAutocomplete.addListener("place_changed", () => {
                let location = this.locationAutocomplete.getPlace();
                if (location.geometry === undefined || location.geometry === null) {
                    this.locationService.getPredictions(this.locationText, (predictions, status) => {
                        if (status === google.maps.places.PlacesServiceStatus.OK) {
                            this.locationService.getPlaceDetails(predictions[0]["place_id"], (places, status) => {
                                if (status === google.maps.GeocoderStatus.OK) {
                                    location = places[0];
                                    this.locationService.setClientLocation(location);
                                    this.locationInputElementRef.nativeElement.blur();
                                }
                            });
                        }
                    });
                } else {
                    this.locationService.setClientLocation(location);
                }
            });
        });
    }

    onBlurLocationInput() {
        this.updateLocationText();
    }

    updateLocationText() {
        this.locationText = this.location ? this.location.formatted_address : "";
        this.cdr.detectChanges();
    }

}
